
import { defineComponent } from "vue";
import store from "@/store/index";

export default defineComponent({
  name: "NotFound",
  data() {
    return {
      signedIn: false,
    };
  },
  created: function () {
    store.getters["user/signInState"].then(() => {
      this.signedIn = true;
    });
  },
});
